import { useEffect } from 'react'

import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { PrivateRoutes, PublicRoutes } from '~/enums/routes'
import storage, { Keys } from '~/helpers/localStorage.service'
import { title } from '~/support/document/metaTags'

const App = dynamic(() => import('../App'), {
  ssr: false,
})

const HomePage = () => {
  const { asPath, push } = useRouter()

  useEffect(() => {
    if (asPath === '/') {
      push(storage.get(Keys.TokenId) ? PrivateRoutes.Home : PublicRoutes.Login)
    }
  }, [asPath, push])

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <App />
    </>
  )
}

export default HomePage
