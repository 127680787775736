const title = 'Portal Admin - Juntos Somos Mais'

const metaTags = [
  {
    attributes: {
      charSet: 'utf-8',
    },
  },
  {
    attributes: {
      name: 'title',
      content: title,
    },
  },
]

export { metaTags, title }
