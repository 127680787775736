export enum Keys {
  TokenId = 'JSMTID',
  Sellers = 'SELLERS',
}

export interface StoredWithExpiration<T> {
  data: T
  expiresIn: number
}

export const mainKeys = [Keys.TokenId]

export interface StoragePlugin {
  set: <T>(key: Keys, data: T) => void
  get: <T>(key: Keys) => T
  remove: (key: Keys) => void
  removeMainKeys: () => void
}

const storage: StoragePlugin = {
  set: (key, data) => {
    localStorage.setItem(
      key,
      typeof data === 'string' ? data : JSON.stringify(data)
    )
  },

  get: (key: string) => {
    const rawData = localStorage.getItem(key) ?? ''
    try {
      return JSON.parse(rawData)
    } catch (error) {
      return rawData
    }
  },

  remove: (key: string) => {
    localStorage.removeItem(key)
  },

  removeMainKeys: () => {
    mainKeys.forEach(key => localStorage.removeItem(key))
  },
}

export default storage
