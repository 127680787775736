export enum PublicRoutes {
  Login = '/login',
  RequestForgotPassword = '/forgot-password',
  RegistryFound = '/forgot-password/registry-found',
  RequestForgotPasswordBlocked = '/forgot-password/blocked',
  ChangePassword = '/forgot-password/change-password',
  ChangePasswordSuccess = '/forgot-password/password-changed',
}

export enum PrivateRoutes {
  Home = '/home',
  Credentials = '/credentials',
  Promotions = '/promotions',
  PromotionsCreate = '/promotions/create',
  MassRegistration = '/mass-registration',
  MassEditing = '/mass-editing',
  MassProductsRegistration = '/products/create',
  MassPricesRegistration = '/prices/create',
  MassStocksRegistration = '/stocks/create',
  MassLotsRegistration = '/lots/create',
  MassProductsEditing = '/productsEdit/products',
  MassPricesEditing = '/pricesEdit/stocks',
  MassStocksEditing = '/stocksEdit/prices',
  MassLotsEditing = '/lotEdit/lot',
  RegistrationHistory = '/registration-history',
  Orders = '/orders',
  Order = '/order',
  HomeCommunication = '/home-communication',
  HomeCommunicationTopBar = '/top-bar',
  HomeCommunicationTopBarCreate = '/top-bar/create',
  HomeCommunicationFixedBanner = '/fixed-banner',
  HomeCommunicationFixedBannerCreate = '/fixed-banner/create',
}
